import React from 'react'
import './ecoast.css'
import { ECOAST_DATA } from '../../const'

export default function Ecoast() {
  return (
    <div className="container mx-auto px-4">
      <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-10 mt-24 place-items-center">
        {ECOAST_DATA.map((ecoast, index) => (
          <div
            key={index}
            className="max-w-md bg-white border border-gray-200 rounded-lg shadow transition duration-500 ease-in-out transform hover:scale-105"
            style={{ height: '500px' }} // Adjust the height as needed
          >
            <a href="#">
              <img
                className="rounded-t-lgf object-cover w-full"
                style={{ height: '200px' }} // Adjust the height as needed
                src={require('../../assets/images/' + ecoast.path)}
                alt={ecoast.title}
              />
            </a>
            <div className="p-5">
              <a href="#">
                <h5 className="mb-2 text-2xl font-bold tracking-tight text-gray-900">
                  {ecoast.title}
                </h5>
              </a>
              <p className="mb-3 font-normal text-gray-700">
                {ecoast.description}
              </p>
              <a
                href={ecoast.redirect}
                className="inline-flex items-center px-3 py-2 text-sm font-medium text-center text-white bg-red-700 rounded-lg hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300"
              >
                Read more
                <svg
                  className="w-3.5 h-3.5 ml-2"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 14 10"
                >
                  <path
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M1 5h12m0 0L9 1m4 4L9 9"
                  />
                </svg>
              </a>
            </div>
          </div>
        ))}
      </div>
    </div>
  )
}
