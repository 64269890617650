import React from 'react'
import ContactUs from '../components/contact/contact'

const ContactUsPage = () => {
  return (
    <>
      <ContactUs />
    </>
  )
}

export default ContactUsPage
