import Footer from "../components/footer/footer";
import Carousel from "../components/carousel/carousel";
import ContactUs from "../components/contact/contact";
import Products from "../components/products/products";
import Ecoast from "../components/ecoast/ecoast";
import ProductsPart from "../components/products-part/products-part";
import { STRUCTURED_CABLING, SURVEILLANCE } from "../const";
import Brands from "../components/brands/brands";

const HomePage = () => {
  return (
    <>
      {/* <Fullpage /> */}
      <Carousel />
      <Ecoast />
      <Products />
      <ProductsPart
        heading="STRUCTURED CABLING"
        PRODUCT_DATA={STRUCTURED_CABLING}
      />
      <ProductsPart
        heading="SURVEILLANCE & SECURITY "
        PRODUCT_DATA={SURVEILLANCE}
      />
      <Brands heading="AFFILIATED BRANDS" />
      <hr class="mx-24 mt-24 h-px my-8 bg-gray-400 border-0 dark:bg-gray-800" />
      <Footer />
    </>
  );
};

export default HomePage;
