import Navbar from "../components/navbar/navbar";
import AppRouter from "./app-router";

function App() {
  return (
    <div>
      <Navbar />
      <AppRouter />
    </div>
  );
}

export default App;
