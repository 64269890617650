import React from 'react'
import './brands.scss' // Import your custom CSS file

export default function Brands({ heading, PRODUCT_DATA }) {
  const imageContext = require.context(
    '../../assets/images/brands',
    false,
    /\.(png|jpe?g|svg)$/
  )

  const images = imageContext.keys().map(imageContext)

  return (
    <>
      <hr className="mx-24 mt-24 h-px my-8 bg-gray-400 border-2" />
      <h1 className="text-center text-3xl font-extrabold text-gray-900 md:text-4xl lg:text-5xl">
        <span className="text-red-700">{heading}</span>
      </h1>
      <hr className="mx-24 h-px my-8 bg-gray-400 border-2" />
      <div class="slider content-center">
        <div class="slide-track">
          {images.map((item, index) => (
            <div class="slide flex h-72 w-full justify-center items-center mx-4">
              <img src={item} height="150" width="300" alt="" />
            </div>
          ))}
        </div>
      </div>
    </>
  )
}
