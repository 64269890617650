import React, { useState } from 'react';
import "./content-header.css";

export default function ContentHeader() {
    const [isMenuOpen, setIsMenuOpen] = useState(false);
        return (
            <header>
               

                <div className="w-full bg-cover bg-center" style={{height: '12rem', backgroundImage: 'url(https://images.unsplash.com/photo-1606765962248-7ff407b51667?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D)'}}>
                    <div className="flex items-center justify-center h-full w-full bg-gray-900 bg-opacity-50">
                        <div className="text-center">
                            <h1 className="text-white text-2xl font-semibold uppercase md:text-3xl">Products</h1>
                        </div>
                    </div>
                </div>
            </header>    
    );
}
