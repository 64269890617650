const PRODUCT_CAT = [
  {
    name: 'Networking',
    path: 'networking.svg',
  },
  {
    name: 'Switching',
    path: 'switching.svg',
  },
  {
    name: 'Optical Transceivers',
    path: 'optical.svg',
  },
  {
    name: 'Fiber Optic Cables',
    path: 'fiber.svg',
  },
  {
    name: 'Panels, Enclosures & Racks',
    path: 'panelsandracks.svg',
  },
  {
    name: 'Wifi Solutions',
    path: 'wifi.svg',
  },
  {
    name: 'Copper Systems',
    path: 'copper.svg',
  },
  {
    name: 'Testers & Tools',
    path: 'tester.svg',
  },
];

const ECOAST_DATA = [
  {
    title: 'Products',
    description:
      'We take pride in offering a comprehensive range of cutting-edge IT Networking products. With our affiliation with renowned and reputed brands in the industry, we guarantee the highest quality and performance.',
    path: 'ecoast-1.jpg',
    redirect: '/products'
  },
  {
    title: 'Solutions',
    description:
      'We offer a range of IT networking solutions to cater to diverse industries. Click below to learn about a breakdown of the solutions we provide.',
    path: 'ecoast-2.jpg',
  },
  {
    title: 'Services',
    description:
      'Our commitment to excellence extends beyond providing high end products and solutions. We offer a range of professional services delivered by our expert team of technicians and engineers.',
    path: 'ecoast-3.jpg',
  },
];

const STRUCTURED_CABLING = [
  {
    title: 'Ethernet Structured Cabling Solutions',
    description:
      "We offer a comprehensive suite of Ethernet structured cabling services, ensuring that your network infrastructure is optimized for performance and scalability. Whether it's copper or fiber cabling, our solutions are tailored to meet the unique requirements of your organization.",
    path: 'cabling/Untitled1.png',
  },
  {
    title: 'Managed and Unmanaged Network Solutions',
    description:
      'We provide managed and unmanaged network solutions designed to suit your business needs. Our team of experts will assess your requirements and deploy the right networking solutions, from simple office setups to complex enterprise environments.',
    path: 'cabling/Untitle4.png',
  },
  {
    title: 'Server Room Infrastructure',
    description:
      'We offer complete server room solutions, including server racks, power systems, Power Distribution Units (PDUs), raised floors, and all essential accessories. These components are critical for the efficient and reliable operation of your network infrastructure.',
    path: 'cabling/Untitled.png',
  },
  {
    title: 'Cable Laying and Termination',
    description:
      'Our experienced technicians ensure precise cable laying, termination, and testing, guaranteeing the highest quality connections. We adhere to industry standards to deliver a seamless and robust network infrastructure.',
    path: 'cabling/Untitled2.png',
  },
  {
    title: 'Commissioning and Quality Assurance',
    description:
      "We don't stop at installation – our team takes pride in commissioning and quality assurance to make certain that your network performs optimally from day one. This includes rigorous testing and validation processes to eliminate any potential issues.",
    path: 'cabling/Untitled3.png',
  },
];

const SURVEILLANCE = [
  {
    title: 'Advanced CCTV and AI-Integrated Surveillance',
    description:
      'We offer a wide range of indoor and outdoor cameras integrated with Artificial Intelligence (AI) for advanced security monitoring. Our CCTV IP systems are designed to provide real-time surveillance, ensuring safety and peace of mind.',
    path: 'security/Untitled5.png',
  },
  {
    title: 'Network Video Recorders (NVR)',
    description:
      'We provide NVR solutions that ensure efficient recording and storage of surveillance footage. Our expertise includes NAS, SAN, local, and cloud-based storage solutions, ensuring easy access to critical data when needed.',
    path: 'security/Untitled8.png',
  },
  {
    title: 'Specialized Cameras',
    description:
      'ECOAST Technologies offers specialized cameras, including Facial Recognition (FR), Automatic Number Plate Recognition (ANPR), and IP Cameras, tailored to meet the unique security needs of businesses. These cameras enhance security in various environments.',
    path: 'security/Untitled6.png',
  },
  {
    title: 'Remote Monitoring',
    description:
      'Our remote monitoring solutions include Pan-Tilt-Zoom (PTZ) cameras, allowing you to remotely control and view specific areas in real-time. This technology enhances situational awareness and response capabilities.',
    path: 'security/Untitled7.png',
  },
  {
    title: 'Security Alarm Systems',
    description:
      'We provide comprehensive security alarm systems that include intrusion detection and notification solutions, enhancing the security of your premises and assets.',
    path: 'security/Untitled9.png',
  },
  {
    title: 'Access Control',
    description:
      'ECOAST Technologies offers cutting-edge access control systems, ensuring that only authorized personnel can enter secure areas. This enhances the physical security of your facilities.',
    path: 'security/Untitled10.png',
  },
];

const BRANDS = [
  "https://jetpunk.b-cdn.net/img/user-photo-library/db/dbd783f784-235.png",
  "https://jetpunk.b-cdn.net/img/user-photo-library/f2/f2abf917d3-235.png",
  "https://jetpunk.b-cdn.net/img/user-photo-library/0f/0f62fc469e-235.png",
  "https://jetpunk.b-cdn.net/img/user-photo-library/c2/c279e26252-235.png",
  "https://jetpunk.b-cdn.net/img/user-photo-library/0d/0dc8b449c1-235.png",
  "https://jetpunk.b-cdn.net/img/user-photo-library/c8/c806540ade-235.png",
  "https://jetpunk.b-cdn.net/img/user-photo-library/f1/f1ccf92269-235.png"
]

export {PRODUCT_CAT, ECOAST_DATA, STRUCTURED_CABLING, SURVEILLANCE, BRANDS};
