import { NavLink } from 'react-router-dom'
import Logo from '../../assets/images/logo.png'
import './navbar.css'
import { useState } from 'react'

export default function Navbar() {
  const [isMenuOpen, setIsMenuOpen] = useState(false)
  // Function to determine if the link is active
  const isActive = (path) => {
    return window.location.pathname === path
  }

  // Function to generate class names
  const getLinkClass = (path) => {
    const baseClasses =
      'block py-2 pl-3 pr-4 rounded hover:bg-gray-100 md:hover:bg-transparent md:border-0 md:p-0'
    return isActive(path)
      ? `${baseClasses} text-red-700`
      : `${baseClasses} text-gray-900`
  }

  return (
    <>
      <nav className="bg-white shadow">
        <div className="container mx-auto px-6 py-3">
          <div className="md:flex md:items-center md:justify-between">
            <div className="flex justify-between items-center">
              <a href="/" class="flex items-center">
                <img src={Logo} class="h-20 mr-3" alt="Ecoast Logo" />
              </a>

              {/* Mobile menu button */}
              <div className="flex md:hidden">
                <button
                  onClick={() => setIsMenuOpen(!isMenuOpen)}
                  type="button"
                  className="text-gray-500 hover:text-gray-600 focus:outline-none focus:text-gray-600"
                  aria-label="toggle menu"
                >
                  <svg viewBox="0 0 24 24" className="h-6 w-6 fill-current">
                    <path
                      fillRule="evenodd"
                      d="M4 5h16a1 1 0 010 2H4a1 1 0 010-2zm0 6h16a1 1 0 010 2H4a1 1 0 010-2zm0 6h16a1 1 0 010 2H4a1 1 0 010-2z"
                    ></path>
                  </svg>
                </button>
              </div>
            </div>

            {/* Mobile Menu open: "block", Menu closed: "hidden" */}
            <div
              className={`${
                isMenuOpen ? 'block' : 'hidden'
              } -mx-4 md:flex md:items-center`}
            >
              <ul class="flex flex-col font-medium p-4 md:p-0 mt-4 border border-gray-100 rounded-lg bg-gray-50 md:flex-row md:space-x-8 md:mt-0 md:border-0 md:bg-white">
                <li>
                  <a
                    href="/"
                    className={getLinkClass('/')}
                  >
                    Home
                  </a>
                </li>
                <li>
                  <a href="/products" className={getLinkClass('/products')}>
                    Products
                  </a>
                </li>
                <li>
                  <a
                    href="#"
                    class="block py-2 pl-3 pr-4 text-gray-900 rounded hover:bg-gray-100 md:hover:bg-transparent md:border-0 md:hover:text-red-700 md:p-0"
                  >
                    Services
                  </a>
                </li>
                <li>
                  <a
                    href="#"
                    class="block py-2 pl-3 pr-4 text-gray-900 rounded hover:bg-gray-100 md:hover:bg-transparent md:border-0 md:hover:text-red-700 md:p-0"
                  >
                    Solutions
                  </a>
                </li>
                <li>
                  <a href="/contact" className={getLinkClass('/contact')}>
                    Contact
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </nav>
    </>
  )
}
