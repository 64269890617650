import React, { useState } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import "./carousel.css";

const Carousel = () => {
  const [currentSlide, setCurrentSlide] = useState(0);

  // Sample carousel data, you can replace this with your own data
  const carouselData = [
    {
      id: 1,
      imageUrl: require("../../assets/images/carousel-img-4.png"),
      heading: "Ethernet – enabling businesses with innovative networks",
      textColor: "white",
    },

    {
      id: 2,
      imageUrl: require("../../assets/images/carousel-img-5.png"),
      heading: "Ethernet – bridging the gap between potential and success",
      textColor: "white",
    },
    {
      id: 3,
      imageUrl: require("../../assets/images/carousel-img-3.png"),
      heading: "Communication at the Speed of Light with Fiber Optics",
      textColor: "white",
    },

    {
      id: 4,
      imageUrl: require("../../assets/images/carousel-img-2.png"),
      heading: "Faster Speeds, Better Connectivity with Optical Fiber",
      textColor: "white",
    },
  ];

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    beforeChange: (oldIndex, newIndex) => {
      setCurrentSlide(newIndex);
    },
    // Customize other settings as needed
  };

  return (
    <div className="max-w mt-2">
      <Slider {...settings}>
        {carouselData.map((item, index) => (
          <div className="relative" key={item.id}>
            <div className="relative">
              <img
                className="h-auto w-full mx-auto img-car"
                src={item.imageUrl}
                alt="image description"
              />
            </div>
            <div
              className={`absolute top-0 left-0 h-full flex flex-col justify-center items-start text-left carousel-overlay ${
                currentSlide === index ? "fade-in" : "fade-out"
              }`}
            >
              <div
                className={`${
                  index === 4 ? "carousel-text-last" : "carousel-text"
                }
                  md:p-8`}
              >
                <h2
                  className={`text-2xl md:text-4xl lg:text-5xl font-bold text-black mb-4 md:mb-6 lg:mb-8 leading-tight text-${item.textColor}`}
                >
                  {item.heading}
                </h2>
              </div>
            </div>
          </div>
        ))}
      </Slider>
    </div>
  );
};

export default Carousel;
