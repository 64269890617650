import React, { useState } from 'react'
import Content from '../components/content/content'
import ContentHeader from '../components/content/content-header/content-header'
import Breadcrumbs from '../components/breadcrumbs/breadcrumbs'

const ProductsPage = () => {
  return (
    <>
      <ContentHeader />
      <div className="p-5">
        <Breadcrumbs />
        <br/>
        <h2 class="text-3xl font-extrabold dark:text-white">Explore our diverse range of products below: </h2>
        <br/>
        <Content />
      </div>
    </>
  )
}

export default ProductsPage
