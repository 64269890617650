import React from 'react'
import './products.css'
import { PRODUCT_CAT } from '../../const'

export default function Products() {
    return (
        <div className="container mx-auto px-4">
            <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-8 gap-10 mt-20">
                {PRODUCT_CAT.map((product, index) => (
                    <div key={index} className="text-center">
                        <div className="flex items-center justify-center">
                            <img
                                src={require('../../assets/' + product.path)}
                                alt={product.name}
                                className="w-20 h-24"
                            />{' '}
                            {/* Assuming the path points to the correct image */}
                        </div>
                        <div className="text-sm">
                            {product.name}
                        </div>
                    </div>
                ))}
            </div>
        </div>
    )
}
