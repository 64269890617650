import React, { useState } from 'react'
import './content.css'
import BulletedPoint from '../../feature/bulleted-point/bulleted-point'

// Dummy content for demonstration
const sections = [
  {
    id: 'section1',
    title: 'Ethernet Solutions',
    content: [
      {
        mainPoint: 'Ethernet Solutions:',
        nestedPoint:
          'We provide reputed Brand with advanced Ethernet switches ensure seamless and reliable data transfer within your network, enhancing productivity and minimizing downtime. ',
      },
      {
        mainPoint: 'Ethernet Routers & Others Accessories:',
        nestedPoint:
          'Experience fast and secure data routing with our top-of-the-line Ethernet routers, designed to optimize network performance. ',
      },
      {
        mainPoint: 'Ethernet Cables:',
        nestedPoint:
          'Choose from a variety of Ethernet cables that provide high-speed connectivity while maintaining signal integrity.',
      },
    ],
  },
  {
    id: 'section2',
    title: 'Fiber Solutions',
    content: [
      {
        mainPoint: 'Fiber Optic Cables:',
        nestedPoint:
          'We provide collection of high-quality fiber optic cables that deliver lightning-fast data transmission over long distances with minimal signal loss. ',
      },
      {
        mainPoint: 'Fiber Optic Transceivers:',
        nestedPoint:
          'Enhance network efficiency with our fiber optic transceivers, offering seamless integration with various networking devices.',
      },
      {
        mainPoint: 'Fiber Optic Patch Panels& Cords:',
        nestedPoint:
          'Organize and manage your fiber connections efficiently with our robust and reliable fiber optic patch panels.',
      },
    ],
  },
  {
    id: 'section3',
    title: 'Network Accessories',
    content: [
      {
        mainPoint: 'Network Switch Modules:',
        nestedPoint:
          'Expand the capabilities of your network switches with our versatile switch modules for added functionality. Network Adapters: Find the perfect network adapters to enable smooth communication between devices and networks.',
      },
      {
        mainPoint: 'Network Cabinets:',
        nestedPoint:
          'Keep your networking equipment secure and organized with our range of sturdy and well-designed network cabinets.',
      },
      {
        mainPoint: 'Fiber Optic Patch Panels& Cords:',
        nestedPoint:
          'Organize and manage your fiber connections efficiently with our robust and reliable fiber optic patch panels.',
      },
    ],
  },
]

export default function Content() {
  const [activeSection, setActiveSection] = useState(sections[0].id)

  return (
    <div className="App flex">
      {/* Table of Contents */}
      <aside className="w-1/4">
        <ul className="flex flex-col">
          {sections.map((section) => (
            <li
              key={section.id}
              className={`p-2 cursor-pointer ${
                activeSection === section.id ? 'bg-gray-200' : 'bg-gray-100'
              }`}
              onClick={() => setActiveSection(section.id)}
            >
              {section.title}
            </li>
          ))}
        </ul>
      </aside>

      {/* Content Area */}
      <main className="w-3/4 p-5">
        {sections.map((section) => (
          <div
            key={section.id}
            className={`content-section ${
              activeSection === section.id ? 'block' : 'hidden'
            }`}
          >
            <h2 class="mb-2 text-lg font-semibold text-gray-900 underline">
              {section.title}
            </h2>
            <BulletedPoint points={section.content} />
          </div>
        ))}
      </main>
    </div>
  )
}
