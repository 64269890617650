import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEnvelope,
  faPhone,
  faLocationDot,
} from "@fortawesome/free-solid-svg-icons";
import Logo from "../../assets/images/logo-white.png";
import "./footer.css";
import Circles from "../../assets/images/squares.png";

export default function Footer() {
  return (
    <footer className="bg-[#ff0606]">
      <div className="mx-10 w-full max-w-screen-xl p-4 py-6 lg:py-8">
        <div>
          <div className="mb-6">
            <a className="flex items-center">
              <img
                src={Logo}
                className="h-8 mr-3 pr-4 border-r-2 border-white"
                alt="Ecoast Logo"
              />
              <span className="self-center text-2xl font-semibold mt-3 whitespace-nowrap font-agency stroke-white">
                TECHNOLOGIES
              </span>
            </a>
          </div>
          <div className="text-white">
            <FontAwesomeIcon icon={faLocationDot} /> Diyawanna Gardens,
            Nugegoda, Colombo
            <br />
            <br />
            <FontAwesomeIcon icon={faPhone} /> +94781293416
            <br />
            <br />
            <FontAwesomeIcon icon={faEnvelope} />{" "}
            <a href="mailto:info@ecoast.lk">info@ecoast.lk</a>
          </div>
        </div>
      </div>
      <div className="px-10 h-full p-5 flex justify-between items-center bg-gradient-to-r from-red-700 via-red-600 to-black to-60%">
        <div>
          <span className="text-xs sm:text-center text-white">
            <p style={{ textAlign: "center" }}>
              © 2024 ECOAST TECHNOLOGIES™ . All Rights Reserved.
            </p>
          </span>
        </div>
        <div>
          <img className="h-14" src={Circles} alt="Circles" />
        </div>
      </div>
    </footer>
  );
}
