import React from 'react'

const BulletedPoint = ({ points }) => {
  return (
    <ul class="space-y-1 text-gray-500 list-disc list-inside dark:text-gray-400">
      {points.map((item, index) => (
        <>
          <li className='text-red-900 font-bold'>{item.mainPoint}</li>
          <div className="ml-5">{item.nestedPoint}</div>
        </>
      ))}
    </ul>
  )
}

export default BulletedPoint
