import { BrowserRouter, Route, Routes } from "react-router-dom";
import HomePage from "../pages/home";
import ProductsPage from "../pages/products"
import ContactUsPage from "../pages/contact-us"

export default function AppRouter() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/products" element={<ProductsPage />} />
        <Route path="/contact" element={<ContactUsPage />} />
      </Routes>
    </BrowserRouter>
  );
}
